import 'spectre.css/dist/spectre.min.css';
import 'spectre.css/dist/spectre-exp.min.css';
import 'spectre.css/dist/spectre-icons.min.css';
import logo from './Budgie.png';
import './App.css';
import { Editor } from './tabs/editor/Editor';
import { getAuth, GoogleAuthProvider, User } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import React, { useEffect, useRef, useState } from 'react';
import { StorageContextProvider } from './context/StorageContext';

//This property is not declared in type for some reason
export type UserWithToken = User & { accessToken: string };

function App() {
	const audioEl = useRef<HTMLAudioElement>(null);
	const authUI = useRef<firebaseui.auth.AuthUI | null>(null);
	const [user, setUser] = useState<UserWithToken | null>(null);
	const [userRoles, setUserRoles] = useState<string[] | undefined>(undefined);
	const auth = getAuth();

	useEffect(() => {
		const uiConfig = {
			callbacks: {
				signInSuccessWithAuthResult: function () {
					if (audioEl.current) {
						audioEl.current.play();
					}
					return true;
				},
			},
			signInFlow: 'popup',
			signInOptions: [GoogleAuthProvider.PROVIDER_ID],
		};
		if (!authUI.current) {
			authUI.current = new firebaseui.auth.AuthUI(auth);
		}
		if (!user) {
			authUI.current.start('#firebaseui-auth-container', uiConfig);
		}
		return auth.onAuthStateChanged(changedUser => {
			if (changedUser) {
				changedUser.getIdTokenResult(true).then(idTokenResult => {
					setUserRoles(idTokenResult.claims.roles ?? []);
				});
			}
			setUser(changedUser as UserWithToken);
		});
	}, [user, setUser, auth]);

	async function signOut() {
		await auth.signOut();
	}

	function isContentEditorUserRole() {
		return userRoles?.includes('content-editor');
	}

	const AuthenticationButton = (props: { user: User | null }) => {
		return (
			<div style={{ position: 'absolute', right: 15, top: 15 }}>
				{!!props.user ? (
					<button onClick={signOut} name="sign out" className="btn">
						🔑
					</button>
				) : (
					<div id="firebaseui-auth-container"></div>
				)}
			</div>
		);
	};

	const Content = () => {
		if (!!user) {
			if (isContentEditorUserRole()) {
				return (
					<div>
						<div>
							<StorageContextProvider>
								<Editor user={user} />
							</StorageContextProvider>
						</div>
						<audio ref={audioEl} src="./chirp.mp3" />
					</div>
				);
			}
			return (
				<div>
					{
						'🔒 Missing correct user role. Please contact dev team for access...'
					}
				</div>
			);
		}
		return <div>{'Please login'}</div>;
	};

	return (
		<div className="App">
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					padding: '10px',
				}}
			>
				<img
					src={logo}
					width={'50px'}
					height={'50px'}
					alt="logo"
					style={{ marginRight: '10px' }}
				/>
				<h1>Budgie</h1>
				<AuthenticationButton user={user} />
			</div>
			<Content />
		</div>
	);
}

export default App;
