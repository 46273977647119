import { JSONEditor } from '@json-editor/json-editor';
import React, { useEffect, useRef, useState } from 'react';
import schema from '../../schema/schema.json';
import { SavedFormsTab } from '../forms/SavedForms.tab';
import { useStorage } from '../../context/StorageContext';
import { UserWithToken } from '../../App';
import PDFUploadTab from '../PDFUpload.tab';

export function Editor(props: { user: UserWithToken }) {
	const editor = useRef<any>();
	const storageCtx = useStorage();
	const [formName, setFormName] = useState('');
	const [metadata, setMetadata] = useState(null);
	const [tabIndex, setTabIndex] = useState(0);

	useEffect(() => {
		if (!editor.current) {
			const container = document.getElementById('container');
			editor.current = new JSONEditor(container, {
				schema: schema,
				disable_properties: true,
				disable_edit_json: true,
				no_additional_properties: true,
				array_controls_top: true,
				theme: 'spectre',
				iconlib: 'spectre',
			});
		}
	}, []);

	useEffect(() => {
		if (editor.current.ready) {
			setMetadata(editor.current.getValue().meta);
		}
	});

	function NavTabs() {
		return (
			<ul className="tab tab-block">
				<li className={'tab-item' + (tabIndex == 0 ? ' active' : '')}>
					<a>
						<button
							onClick={() => setTabIndex(0)}
							style={{
								border: 'none',
								background: 'none',
							}}
						>
							Editor
						</button>
					</a>
				</li>
				<li className={'tab-item' + (tabIndex == 1 ? ' active' : '')}>
					<a>
						<button
							onClick={() => setTabIndex(1)}
							style={{
								border: 'none',
								background: 'none',
							}}
						>
							PDF Uploader
						</button>
					</a>
				</li>
				<li className={'tab-item' + (tabIndex == 2 ? ' active' : '')}>
					<a>
						<button
							onClick={() => setTabIndex(2)}
							style={{
								border: 'none',
								background: 'none',
							}}
						>
							Forms
						</button>
					</a>
				</li>
			</ul>
		);
	}

	return (
		<div>
			<NavTabs />
			<div
				style={{ display: tabIndex == 0 ? 'block' : 'none' }}
				className="container"
			>
				<div
					style={{
						border: '1px solid lightgray',
						margin: '10px',
						borderRadius: 5,
					}}
					className="columns"
				>
					<div className={'column col-2'}>
						<label>Currently Open:</label>
						<h5>
							{formName
								? formName.slice(0, formName.length - 5)
								: 'Unsaved draft'}
						</h5>
					</div>
					<div className="column col-7"></div>
					<div className={'column col-3 columns'}>
						<button
							onClick={() => {
								storageCtx?.saveForm(
									editor.current.getValue(),
									formName,
								);
							}}
							className={'btn btn-primary col-5 mr-2'}
						>
							SAVE ✔️
						</button>
						<button
							onClick={() => {
								editor.current.setValue({});
								setFormName('');
								setMetadata(null);
							}}
							className={'btn btn col-5'}
						>
							NEW FORM 🦜
						</button>
					</div>
				</div>

				<div id="container" />
			</div>

			{tabIndex == 1 && (
				<div>
					<div
						style={{
							border: '1px solid lightgray',
							margin: '10px',
							borderRadius: 5,
						}}
						className="columns"
					>
						<div className={'column col-2'}>
							{formName ? (
								<div>
									<label>Upload PDF for:</label>
									<h5>{formName}</h5>
								</div>
							) : (
								<button
									className="btn"
									onClick={() => setTabIndex(2)}
								>
									Open a form first
								</button>
							)}
						</div>
					</div>
					{metadata && <PDFUploadTab metadata={metadata} />}
				</div>
			)}

			{tabIndex == 2 && (
				<SavedFormsTab
					accessToken={props.user.accessToken}
					editForm={(fm, formData) => {
						editor.current.setValue(formData);
						setFormName(fm);
						setMetadata(editor.current.getValue().meta);
						setTabIndex(0);
					}}
				/>
			)}
		</div>
	);
}
