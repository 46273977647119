import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useStorage } from '../context/StorageContext';

type Metadata = {
	insuranceName: string;
	contact: { email: string; fax: string };
	applicationType: string;
	language: string;
};

export default function PDFUploadTab(props: { metadata: Metadata | null }) {
	const [disabled, setDisabled] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(false);
	const storageCtx = useStorage();

	const getFileName = () => {
		return (
			props.metadata?.insuranceName +
			'_' +
			props.metadata?.applicationType
		);
	};

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			acceptedFiles.forEach(file => {
				setLoading(true);
				const reader = new FileReader();
				reader.onabort = () => {
					setLoading(false);
				};
				reader.onerror = () => {
					setLoading(false);
				};
				reader.onload = async () => {
					const binaryStr = reader.result;
					const fileName = getFileName();
					await storageCtx?.savePDF(binaryStr, fileName);
					setLoading(false);
				};
				reader.readAsArrayBuffer(file);
			});
		},
		[props.metadata?.insuranceName, props.metadata?.applicationType],
	);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		disabled,
	});

	const validateMetadata = (metadata: Metadata) => {
		return (
			metadata.applicationType.length > 0 &&
			metadata.insuranceName.length > 0
		);
	};

	const renderMessage = () => {
		const searchFileName = getFileName() + '.pdf';
		if (
			storageCtx?.pdfs.some(file => {
				return file.name === searchFileName;
			})
		) {
			return `Drag & Drop to replace ${searchFileName}`;
		} else return 'Drag & drop the PDF File Here';
	};

	useEffect(() => {
		if (props.metadata) {
			setDisabled(!validateMetadata(props.metadata));
		}
	}, [props.metadata]);

	return (
		<>
			{!disabled && (
				<div
					style={{ border: '2px dashed gray' }}
					className="empty text-center my-2 mx-2"
					{...getRootProps()}
				>
					<input {...getInputProps()} />
					<p className={loading ? 'loading' : ''}>
						{renderMessage()}
					</p>
				</div>
			)}
		</>
	);
}
