import {
	deleteObject,
	getBlob,
	getStorage,
	list,
	ref,
	StorageReference,
	uploadBytes,
} from 'firebase/storage';
import React, {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react';
import { FormPart, PDFMapping } from '../schema/schema.interface';
import { IForm } from '../tabs/forms/types';
import { deleteDoc, getFirestore } from 'firebase/firestore';
import { collection, onSnapshot, setDoc, doc } from 'firebase/firestore';
interface IStorageContext {
	forms: StorageReference[];
	saveForm: (data: PDFMapping, formName?: string) => void;
	pdfs: StorageReference[];
	savePDF: (data: any, name: string) => Promise<void>;
	deleteForm: (form: IForm) => Promise<void>;

	budgieMetaData: IBudgieMetaData[];
	updateBudgieMetaData: (metaData: IBudgieMetaData) => void;
}

/**
 * Additional data about an application which is not stored as part of the .json file. Instead, this is saved in nui-content's firestore.
 * Therefore, this data is exclusive to budgie and should not contain anything that is used by the app or cloud-functions
 */
export interface IBudgieMetaData {
	id: string;
	readyToDeploy: boolean;
}
const StorageContext = createContext<IStorageContext | undefined>(undefined);
const fireStorePath = '/forms';

export function StorageContextProvider(props: { children: ReactNode }) {
	const [forms, setForms] = useState<StorageReference[]>([]);
	const [pdfs, setPdfs] = useState<StorageReference[]>([]);
	const [budgieMetaData, setBudgieMetaData] = useState<IBudgieMetaData[]>([]);
	async function fetchForms() {
		const storage = getStorage();
		const listRef = ref(storage, 'forms');
		try {
			const files = await list(listRef);
			const forms = files.items.filter(item => {
				return item.name.endsWith('.json');
			});
			const pdfs = files.items.filter(item => {
				return item.name.endsWith('.pdf');
			});
			setForms(forms);
			setPdfs(pdfs);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		void fetchForms();
		const unlistenMetaData = listenMetaData();
		return () => unlistenMetaData();
	}, []);

	function listenMetaData() {
		const forms = collection(getFirestore(), fireStorePath);
		return onSnapshot(forms, snap => {
			const _metaData: IBudgieMetaData[] = [];
			snap.docs.forEach(doc => {
				console.log(doc.id);
				console.log(doc.data());
				_metaData.push({
					id: doc.id,
					readyToDeploy: doc.data()?.readyToDeploy ?? false,
				});
			});
			setBudgieMetaData(_metaData);
		});
	}

	function updateBudgieMetaData(metaData: IBudgieMetaData): Promise<void> {
		const forms = collection(getFirestore(), fireStorePath);
		return setDoc(doc(forms, metaData.id), {
			readyToDeploy: metaData.readyToDeploy,
		});
	}

	function deleteMetaData(id: string): Promise<void> {
		const forms = collection(getFirestore(), fireStorePath);
		return deleteDoc(doc(forms, id));
	}

	async function saveForm(data: PDFMapping, formName?: string) {
		try {
			data = uniquifyPersistenceKeys(data);
			const storage = getStorage();
			const fileName =
				data.meta.insuranceName + '_' + data.meta.applicationType;
			const fileRef = ref(storage, `forms/${fileName}.json`);
			const str = JSON.stringify(data);
			const bytes = new TextEncoder().encode(str);
			const blob = new Blob([bytes], {
				type: 'application/json;charset=utf-8',
			});
			await uploadBytes(fileRef, blob);
			// If metadata was changed Budgie will set up a new reference based
			// on new metadata, this avoids creating duplicates each time metadata
			// is changed
			const currentFormName = formName?.replace('.json', '');
			if (currentFormName && fileName !== currentFormName) {
				// deletes old json file
				const oldRef = ref(storage, `forms/${currentFormName}.json`);
				await deleteObject(oldRef);
				//deletes old pdf and re-uploads it with new name
				updatedPDFName(currentFormName, fileName);
			}
			await fetchForms();
		} catch (error) {
			console.error(error);
		}
	}

	async function updatedPDFName(oldName: string, newName: string) {
		try {
			const storage = getStorage();
			const oldRef = ref(storage, `forms/${oldName}.pdf`);
			const blob = await getBlob(oldRef);
			const newRef = ref(storage, `forms/${newName}.pdf`);
			await deleteObject(oldRef);
			await uploadBytes(newRef, blob);
			await fetchForms();
		} catch (error) {
			console.error(error);
		}
	}

	async function savePDF(data: any, name: string) {
		try {
			const storage = getStorage();
			const fileRef = ref(storage, `forms/${name}.pdf`);
			await uploadBytes(fileRef, data);
			await fetchForms();
		} catch (error) {
			console.error(error);
		}
	}

	/**
	 *
	 * Persistence Keys are dot separated strings with an optional enumeration
	 * on each level. This design
	 * - Reflects the hierarchy and thus each fields semantic
	 * - Supports persisting & UI-logic due to uniqueness of each key
	 */
	function uniquifyPersistenceKeys(data: PDFMapping): PDFMapping {
		function depthFirstSearch(currPath: string, node: FormPart) {
			if (!node || !node.persistenceKey) {
				console.error('Missing persistence key at node', node);
				return;
			}
			// determines if it was uniquified during a previous save
			const isUniquified = node.persistenceKey.includes('.');
			if (!isUniquified) {
				if (currPath) {
					currPath += `.${node.persistenceKey}`;
				} else {
					currPath = node.persistenceKey;
				}
				if (persistenceKeys.has(currPath)) {
					const count = persistenceKeys.get(currPath);
					node.persistenceKey = `${currPath}.${count + 1}`;
					persistenceKeys.set(currPath, count + 1);
				} else {
					node.persistenceKey = currPath;
					persistenceKeys.set(currPath, 1);
				}
			} else {
				// This is valid because form elements can not be moved in hierarchy
				currPath = node.persistenceKey;
				const parts = node.persistenceKey.split('.');
				const lastIdx = parts.length - 1;
				const parsedCount = parseInt(parts[lastIdx]);
				if (!isNaN(parsedCount)) {
					const basePath = parts.splice(lastIdx - 1).join('.');
					let count: number;
					if (persistenceKeys.has(basePath)) {
						count = Math.max(
							parsedCount,
							persistenceKeys.get(currPath) + 1,
						);
					} else {
						count = parsedCount;
					}
					persistenceKeys.set(basePath, count);
				} else {
					if (persistenceKeys.has(currPath)) {
						console.info(persistenceKeys.get(currPath));
					} else {
						persistenceKeys.set(currPath, 1);
					}
				}
			}
			for (let i = 0; node.children && i < node.children.length; i++) {
				depthFirstSearch(currPath, node.children[i]);
			}
			for (let i = 0; node.values && i < node.values.length; i++) {
				depthFirstSearch(currPath, node.values[i]);
			}
		}
		const persistenceKeys = new Map();
		for (let i = 0; i < data.formSections.length; i++) {
			depthFirstSearch('', data.formSections[i]);
		}
		return data;
	}

	async function deleteForm(form: IForm): Promise<void> {
		await deleteObject(form.formRef);
		if (form.pdfRef) {
			await deleteObject(form.pdfRef);
		}
		await deleteMetaData(form.baseName);
		await fetchForms();
		window.alert(`${form.formRef} was deleted`);
	}

	return (
		<StorageContext.Provider
			value={{
				forms,
				saveForm,
				pdfs,
				savePDF,
				deleteForm,
				budgieMetaData,
				updateBudgieMetaData,
			}}
		>
			{props.children}
		</StorageContext.Provider>
	);
}

export function useStorage() {
	return useContext(StorageContext);
}
