import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
	apiKey: 'AIzaSyDxZJecgbZaEonIxVgABnhERPuQiN3tEQM',
	authDomain: 'nui-content.firebaseapp.com',
	databaseURL: 'https://nui-content.firebaseio.com',
	projectId: 'nui-content',
	storageBucket: 'nui-content.appspot.com',
	messagingSenderId: '835696353831',
	appId: '1:835696353831:web:338b225e97c43a170f2402',
};

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);
