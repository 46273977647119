import React, { useEffect, useState } from 'react';
import { useStorage } from '../../context/StorageContext';

enum DeploymentEnvironments {
	feature = 'feature',
	develop = 'develop',
	release = 'release',
	production = 'production',
}

interface BudgieDeployRequestBody {
	environment: DeploymentEnvironments;
	formsToDeploy: string[];
}

const DeployForm = (props: { accessToken: string }) => {
	const [deployTarget, setDeployTarget] = useState<DeploymentEnvironments>(
		DeploymentEnvironments.feature,
	);
	const [message, setMessage] = useState<string | undefined>(undefined);
	const storage = useStorage();
	const renderMessage = () => {
		if (message === 'loading') {
			return <div className="loading"></div>;
		} else {
			if (message) {
				return <div>{message}</div>;
			}
		}
	};

	useEffect(() => {
		if (message && message !== 'loading') {
			setTimeout(() => {
				setMessage(undefined);
			}, 5000);
		}
	}, [message]);

	const deployFunction = async () => {
		setMessage('loading');
		const myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + props.accessToken);
		myHeaders.append('Content-Type', 'application/json');

		const formsToDeploy =
			storage?.budgieMetaData
				.filter(m => m.readyToDeploy === true)
				.map(m => m.id) ?? [];

		const requestBody: BudgieDeployRequestBody = {
			environment: deployTarget,
			formsToDeploy,
		};

		const requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(requestBody),
		};
		try {
			const response = await fetch(
				'https://europe-west1-nui-content.cloudfunctions.net/budgieDeploy',
				requestOptions,
			);
			if (response.status !== 200) {
				console.log('Failed!');
				setMessage(`Failed: ${response.status}`);
				return;
			}
			setMessage('Success');
			console.log('Success!');
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<div className="d-flex">
			<div className="col-3" />
			<label className="col-2 m-2 text-right text-large">
				Deploy to:
			</label>
			<div className="col-2 mr-2 my-1">
				<select
					value={deployTarget}
					onChange={e =>
						setDeployTarget(
							e.target.value as DeploymentEnvironments,
						)
					}
					className="form-input"
				>
					{Object.values(DeploymentEnvironments).map(
						(stage, index) => {
							return (
								<option key={index} value={stage}>
									{stage}
								</option>
							);
						},
					)}
				</select>
			</div>
			<button
				className={'btn btn-m btn-primary mr-2 my-1 col-2'}
				onClick={deployFunction}
			>
				DEPLOY
			</button>
			<div className="col-1">{renderMessage()}</div>
			<div className="col-2" />
		</div>
	);
};

export default DeployForm;
