import React from 'react';
import { IForm } from './types';
import { useStorage } from '../../context/StorageContext';

export const FormRow = (props: {
	cssClass: string;
	form: IForm;
	openForm(): void;
	deleteForm(): Promise<void>;
}) => {
	const { form } = props;
	const storageCtx = useStorage();
	function onDelete(): Promise<void> | undefined {
		const confirmed = window.confirm(
			'Do you really want to delete this application?',
		);
		if (confirmed) {
			return props.deleteForm();
		}
	}
	return (
		<tr key={form.baseName} className={props.cssClass}>
			<td>
				<button
					style={{ color: 'red', borderColor: 'red' }}
					onClick={onDelete}
					className="btn"
				>
					DELETE 🗑 ️
				</button>
			</td>
			<td style={{ textAlign: 'left' }}>{form.baseName}</td>
			<td>
				<input
					type={'checkbox'}
					disabled={!form.pdfRef}
					checked={
						storageCtx?.budgieMetaData.find(
							f => f.id === props.form.baseName,
						)?.readyToDeploy
					}
					onChange={event => {
						storageCtx?.updateBudgieMetaData({
							id: form.baseName,
							readyToDeploy: event.target.checked,
						});
					}}
				></input>
			</td>
			<td>{form.pdfRef && <span className="chip">PDF ✔️</span>}</td>
			<td>
				<button onClick={() => props.openForm()} className="btn">
					OPEN
				</button>
			</td>
		</tr>
	);
};
