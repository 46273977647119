import { getBlob, StorageReference } from 'firebase/storage';
import { useStorage } from '../../context/StorageContext';
import React, { useEffect, useState } from 'react';
import { PDFMapping } from '../../schema/schema.interface';
import { IForm } from './types';
import { FormRow } from './FormRow';
import DeployForm from '../editor/DeployForms';

export function SavedFormsTab(props: {
	editForm: (formName: string, formData: PDFMapping) => void;
	accessToken: string;
}) {
	const storageCtx = useStorage();
	const [forms, setForms] = useState<IForm[]>([]);

	useEffect(() => {
		if (storageCtx?.forms) {
			const formsUpdate = [];
			for (let storageRef of storageCtx?.forms) {
				const baseName = storageRef.name.slice(
					0,
					storageRef.name.length - 5,
				);
				const pdfRef = storageCtx.pdfs.find(storageRef => {
					return (
						baseName ==
						storageRef.name.slice(0, storageRef.name.length - 4)
					);
				});
				const budgieForm = { baseName, formRef: storageRef, pdfRef };
				formsUpdate.push(budgieForm);
			}
			setForms(formsUpdate);
		}
	}, [storageCtx?.forms, storageCtx?.pdfs]);

	async function openForm(storageRef: StorageReference) {
		try {
			const file = await getBlob(storageRef);
			const form: PDFMapping = JSON.parse(await file.text());
			props.editForm(storageRef.name, form);
		} catch (error) {
			console.error(error);
		}
	}

	async function deleteForm(form: IForm) {
		await storageCtx?.deleteForm(form);
	}

	return (
		<div
			style={{
				border: '1px solid lightgray',
				margin: '10px',
				borderRadius: 5,
			}}
		>
			<h1>Deployment</h1>
			<DeployForm accessToken={props.accessToken} />
			<h1 style={{ marginTop: 25 }}>Forms</h1>
			<table style={{ width: '100%', borderColor: 'grey' }}>
				<thead>
					<tr className={'bg-primary'}>
						<td></td>
						<td>Name</td>
						<td>include in deployment</td>
						<td>PDF available</td>
						<td></td>
					</tr>
				</thead>
				<tbody>
					{forms &&
						forms.map((budgieForm, index) => {
							return (
								<FormRow
									cssClass={
										index % 2 === 0
											? 'bg-gray'
											: 'primary bg'
									}
									key={budgieForm.baseName}
									form={budgieForm}
									openForm={() => {
										openForm(budgieForm.formRef).catch(
											console.error,
										);
									}}
									deleteForm={() => deleteForm(budgieForm)}
								/>
							);
						})}
				</tbody>
			</table>
		</div>
	);
}
